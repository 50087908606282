
import { BInput, BSelect, BSwitch } from 'buetify/lib/components';
import BField from 'buetify/lib/components/form/field';
import BHorizontalDivider from 'buetify/lib/components/layout/divider/BHorizontalDivider';
import BPagination, { PaginationPosition, PaginationSize } from 'buetify/lib/components/pagination/BPagination';
import { defineComponent, shallowRef } from 'vue';

interface Option<T> {
	value: T;
	text: string;
}

const positions: Option<PaginationPosition>[] = [
	{
		value: 'is-right',
		text: 'Right'
	},
	{
		value: 'is-centered',
		text: 'Center'
	},
	{
		value: '',
		text: 'Default'
	}
];

const sizes: Option<PaginationSize>[] = [
	{
		value: 'is-small',
		text: 'Small'
	},
	{
		value: '',
		text: 'Default'
	},
	{
		value: 'is-medium',
		text: 'Medium'
	},
	{
		value: 'is-large',
		text: 'Large'
	}
];

export default defineComponent({
	name: 'simple-pagination-example',
	components: {
		BPagination,
		BSelect,
		BSwitch,
		BInput,
		BField,
		BHorizontalDivider
	},
	setup() {
		const total = shallowRef(200);
		const itemsPerPage = shallowRef(20);
		const position = shallowRef<PaginationPosition>('');
		const isSimple = shallowRef(false);
		const isRounded = shallowRef(false);
		const size = shallowRef<PaginationSize>('');
		const currentPage = shallowRef(1);

		return {
			total,
			itemsPerPage,
			position,
			isSimple,
			isRounded,
			size,
			sizes,
			positions,
			currentPage
		};
	}
});
